<template>
  <div>
    <b-card title="">
      <div class="card-title">
        <div class="row">
          <div class="col-md-6">
            <h4 class="mb-0">{{ title }}</h4>
          </div>
          <div class="col-md-6 text-right">
            <a
              :href="`${
                themeConfig.apiUrl
              }/recipes/exports/reviews.xlsx?d=${Date.now()}`"
              class="btn btn-info btn-icon mr-1"
            >
              Export
            </a>
          </div>
        </div>
      </div>
      <p v-if="notes.length == 0" class="text-center text-muted">
        <em>Aucun résultat</em>
      </p>
    </b-card>
    <div v-for="note in notes" :key="note.id" class="card">
      <div class="card-header">
        <h2 class="h4">
          Le {{ $dayjs(note.created_at).format('DD-MM-YYYY') }} -
          <router-link :to="`/subscriptions/selections/${note.model_id}`">
            Sélection
          </router-link>
        </h2>
      </div>
      <div class="card-body">
        <span v-if="note.content.length > 1">
          {{ note.content }}
        </span>
        <span v-else class="text-muted"> Sans commentaires </span>
      </div>
    </div>
    <pagination-custom
      :page="filters.page"
      :total="pagination.total"
      :per-page="filters.per_page"
      class="text-center mt-3"
      @change="setPage"
    />
    <!-- <debug>{{ notes }}</debug> -->
    <!-- <debug>{{ pagination }}</debug> -->
    <!-- <debug>{{ filters }}</debug> -->
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  data() {
    return {
      themeConfig: $themeConfig,
      displayFilters: false,
      displayForm: false,
      notes: [],
      pagination: { total: 0 },
      filters: {
        page: 1,
        per_page: 10,
        status: 'PUBLIC',
        model_name: 'USER_SELECTION',
      },
    }
  },
  computed: {
    title() {
      if (this.pagination.total) {
        return `${this.pagination.total} éléments - page ${this.pagination.page} / ${this.pagination.pages}`
      }
      return '…'
    },
  },
  watch: {
    $route() {
      this.init()
    },
  },
  async created() {
    this.filters = Object.assign(this.filters, this.$route.query)
    this.init()
  },
  methods: {
    async init() {
      if (this.filters.term) {
        this.displayFilters = true
      }
      const resp = await this.$http.get('/notes/', {
        params: Object.assign(this.filters, this.$route.query),
        headers: {
          'X-Fields': 'results{*},meta',
        },
      })
      // console.log(JSON.parse(JSON.stringify(resp.data.results)))
      this.notes = resp.data.results
      this.pagination = resp.data.meta
      this.$forceUpdate()
      this.scrollToTop()
    },
    setPage(page) {
      if (page) {
        this.filters.page = page
      }
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: this.filters,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onCreate(note) {
      this.displayForm = false
      this.$router.push(`/notes/${note.id}/`)
    },
  },
}
</script>

<style></style>
